<template>
  <CModal :show="show" size="xl" addContentClasses="card" :closeOnBackdrop="false">
    <template slot="header-wrapper">
      <CCardHeader>
        <CIcon name="cil-image1" />
        {{titulo}}
        <CButtonClose buttonClasses="text-muted close" @click="fechar" />
      </CCardHeader>
    </template>
    <template slot="body-wrapper">
      <CCardBody>
        <loader :loading="requisicaoAberta"></loader>
        <CRow>
          <CCol sm="12">
            <vue-plotly v-if="vizualisarGrafico" :data="data" :layout="layout" :options="options" />
          </CCol>
        </CRow>
      </CCardBody>
    </template>
    <template slot="footer-wrapper">
      <CCardFooter>
        <CButton color="success" size="sm" class="float-right">
          <CIcon name="cil-save"></CIcon>&nbsp;
        </CButton>
      </CCardFooter>
    </template>
  </CModal>
</template>

<script>
import VuePlotly from "@statnett/vue-plotly";
import Axios from "axios";
import mixin from "../../shared/mixin";
import mixinEstrutural from "./mixinsEstrutural";
export default {
  name: "GraficoRecalque",
  components: { VuePlotly },
  mixins: [mixin, mixinEstrutural],
  props: {
    show: { type: Boolean, default: false },
    idFundacao: { type: Number }
  },
  inject: ["Estrutural"],
  data() {
    return {
      requisicaoAberta: false,
      titulo: "",
      data: [{ x: [], y: [] }],
      layout: {
        autosize: true,
        title: "Recalque / Acréscimo de Tensão",
        xaxis: {
          title: "Recalque (mm)",
          showticklabels: true,
          tickangle: "auto",
          dtick: 0.5
        },
        yaxis: {
          title: "Profundidade (m)",
          autorange: "reversed",
          showticklabels: true,
          tickangle: "auto",
          dtick: 1
        },
        xaxis2: {
          title: "Acréscimo de Tensão (kPa)",
          showticklabels: true,
          tickangle: "auto",
          dtick: 20
        },
        legend: {
          x: 1,
          y: 0.5
        },
        grid: { rows: 1, columns: 2, subplots: [["xy", "x2y"]] }
      },
      options: {},
      vizualisarGrafico: false
    };
  },
  methods: {
    fechar() {
      this.vizualisarGrafico = false;
      this.Estrutural.fecharGraficoAnalise();
    },
    montarUrlRequisicao(idFundacao) {
      let idProjeto = this.obtenhaIdProjeto();
      let idEstrutural = this.obtenhaIdEstrutural();
      return `projeto/${idProjeto}/estrutural/${idEstrutural}/fundacao/${idFundacao}/recalque`;
    },
    carregarDadosGrafico(item) {
      let url = this.montarUrlRequisicao(item);
      this.requisicaoAberta = true;
      let tubulao = this.Estrutural.fundacoes.find(
        elemento => elemento.id == item
      );
      this.layout.title = `${tubulao.identificacao} - Recalque / Acréscimo de Tensão`;
      Axios.post(url, {})
        .then(resposta => {
          let acrescimoTensao = this.montarSerie(
            resposta.data.retorno.acrescimoTensaoCamada
          );
          let recalqueCamadas = this.montarSerie(
            resposta.data.retorno.recalqueCamadas
          );
          this.$set(recalqueCamadas, "name", "Recalque");
          this.$set(acrescimoTensao, "name", "Acréscima de Tensão");
          this.$set(acrescimoTensao, "line", { shape: "spline" });
          this.$set(acrescimoTensao, "xaxis", "x2");
          this.$set(acrescimoTensao, "yaxis", "y");
          this.data = [acrescimoTensao, recalqueCamadas];
          this.vizualisarGrafico = true;
        })
        .catch(erro => {
          console.log(erro);
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    },
    montarSerie(dados) {
      let nElementos = dados.length;
      let tensao = [];
      let camada = [];
      for (let i = 0; i < nElementos; i++) {
        tensao.push(dados[i][1]);
        camada.push(dados[i][0]);
      }
      return { x: tensao, y: camada };
    }
  },
  mounted() {
    this.data[0].x = [2, 1.8, 3];
    this.data[0].y = [1, 2, 3];
  },
  watch: {
    show(val) {
      if (val) this.carregarDadosGrafico(this.idFundacao);
    }
  }
};
</script>

<style scoped>
.modal-content > .card-body {
  height: 80vh;
}
.modal-content > .card-body > .row > .col-sm-12 > .vue-plotly {
  height: 75vh;
}
</style>
