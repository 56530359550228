const mixinEstrutural = {
  provide () {
    return { Estrutural: this };
  },
  data () {
    return {
      janelaRecalque: false,
      fundacaoAtiva: 0,
    }
  },
  methods: {
    obtenhaIdEstrutural () {
      return this.$route.params.estruturalID;
    },
    abrirGraficoAnalises (item) {
      this.fundacaoAtiva = item;
      this.janelaRecalque = true;
    },
    fecharGraficoAnalise () {
      this.janelaRecalque = false;
    }

  }
}


export default mixinEstrutural;
